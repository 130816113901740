@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Core Gothic D";
  src: url("./fonts/CoreGothicD-8Heavy.woff2") format("woff2");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "Core Gothic D";
  src: url("./fonts/CoreGothicD-5Medium.woff2") format("woff2");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Core Gothic D";
  src: url("./fonts/CoreGothicD-2ExtraLight.woff2") format("woff2");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: "Core Gothic D";
  src: url("./fonts/CoreGothicD-1Thin.woff2") format("woff2");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "Core Gothic D";
  src: url("./fonts/CoreGothicD-6Bold.woff2") format("woff2");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Core Gothic D";
  src: url("./fonts/CoreGothicD-9Black.woff2") format("woff2");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Core Gothic D";
  src: url("./fonts/CoreGothicD-4Regular.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Core Gothic D";
  src: url("./fonts/CoreGothicD-7ExtraBold.woff2") format("woff2");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "Core Gothic D";
  src: url("./fonts/CoreGothicD-3Light.woff2") format("woff2");
  font-weight: 300;
  font-style: normal;
}

body {
  height: 100%;
  margin: 0;
  font-family: "Core Gothic D";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* min-width: 482px; */
  min-width: 300px;
}

code {
  font-family: "Core Gothic D";
}

[type="radio"] {
  vertical-align: middle;
  appearance: none;
  border: max(2px, 0.1em) solid lightgray;
  border-radius: 50%;
  width: 1.25em;
  height: 1.25em;
}

[type="radio"]:checked {
  border: 0.4em solid tomato;
}

[type="radio"]:focus-visible {
  outline-offset: max(2px, 0.1em);
  outline: max(2px, 0.1em) dotted tomato;
}

[type="radio"]:disabled {
  background-color: lightgray;
  box-shadow: none;
  opacity: 0.7;
  cursor: not-allowed;
}

[type="radio"]:disabled+span {
  opacity: 0.7;
  cursor: not-allowed;
}

.ReactModal__Overlay {
  z-index: 1000;

}

.ReactModal__Content:not([aria-label="EvPlug popup"]) {
  min-width: 300px;

  @media only screen and (max-width: 900px) {
    width: 100%;
  }
}




.scrollBar {
  /* width: 200px;
  height: 200px; */
  overflow-y: scroll;
}

.scrollBar::-webkit-scrollbar {
  width: 7px;
  /* 스크롤바의 너비 */
}

.scrollBar::-webkit-scrollbar-thumb {
  height: 20%;
  /* 스크롤바의 길이 */
  background: #cecdcd;
  /* 스크롤바의 색상 */

  border-radius: 10px;
}

.scrollBar::-webkit-scrollbar-track {
  background: rgba(234, 235, 235, 0.1);
  /*스크롤바 뒷 배경 색상*/
}

:-webkit-autofill,
:-webkit-autofill:hover,
:-webkit-autofill:focus,
:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
  -webkit-transition: background-color 9999s ease-out;
  -webkit-box-shadow: 0 0 0px 1000px white inset !important;
  box-shadow: 0 0 0px white inset !important;
  /* -webkit-text-fill-color: #fff !important; */
}

/*shw*/
.Dropdown-root {
  position: relative;
  font-size: calc(0.7rem + 0.3vw);
}

.Dropdown-control {
  position: relative;
  overflow: hidden;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 2px;
  box-sizing: border-box;
  color: #333;
  cursor: default;
  outline: none;
  /* padding: 8px 52px 8px 10px; */
  /* padding: 4px; */
  transition: all 200ms ease;
  padding: 0.375rem 0.75rem;
}

.Dropdown-control:hover {
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
}

.Dropdown-arrow {
  border-color: #999 transparent transparent;
  border-style: solid;
  border-width: 5px 5px 0;
  content: " ";
  display: block;
  height: 0;
  margin-top: -ceil(2.5);
  position: absolute;
  right: 10px;
  top: 14px;
  width: 0;
}

.is-open .Dropdown-arrow {
  border-color: transparent transparent #999;
  border-width: 0 5px 5px;
}

.Dropdown-menu {
  background-color: white;
  border: 1px solid #ccc;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
  box-sizing: border-box;
  margin-top: -1px;
  max-height: 200px;
  overflow-y: auto;
  position: absolute;
  top: 100%;
  width: 100%;
  z-index: 1000;
  -webkit-overflow-scrolling: touch;
}

.Dropdown-menu .Dropdown-group>.Dropdown-title {
  padding: 8px 10px;
  color: rgba(51, 51, 51, 1);
  font-weight: bold;
  text-transform: capitalize;
}

.Dropdown-option {
  box-sizing: border-box;
  color: rgba(51, 51, 51, 0.8);
  cursor: pointer;
  display: block;
  padding: 8px 10px;
}

.Dropdown-option:last-child {
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 2px;
}

.Dropdown-option:hover {
  background-color: #f2f9fc;
  color: #333;
}

.Dropdown-option.is-selected {
  background-color: #f2f9fc;
  color: #333;
}

.Dropdown-noresults {
  box-sizing: border-box;
  color: #ccc;
  cursor: default;
  display: block;
  padding: 8px 10px;
}

.filebox .upload-name {
  display: inline-block;
  /* padding: 5px; */
  vertical-align: middle;
  border: 1px solid #dddddd;
  width: 78%;
  color: #000;
}

.filebox input[type="file"] {
  width: 100%;
  padding: 0px;
  height: 2rem;
  border: 1px solid #dddddd;
}

input {
  display: flex;
  align-items: center;
  /* top: 2px; */
}

::-webkit-input-placeholder {
  display: flex;
  align-items: center;
  /* position: relative; */
  /* top: 2px; */
}

:-moz-placeholder {
  display: flex;
  align-items: center;
  /* position: relative; */
  /* top: 2px; */
}

input[type="file"]::file-selector-button {
  background: #e9ecef;
  /* border: 1px solid #dddddd; */
  border: 0px;
  border-right: 1px solid #dddddd;
  cursor: pointer;
  height: 100%;
  width: 22%;
  margin-right: 10px;
  min-width: 70px;
}

input[type="file"]::file-selector-button:hover {
  background: #dee2e6;
}

button[disabled] {
  border: 1px solid #000;
  /* background-color: #cccccc;
  color: #666666; */
  opacity: 0.6;
  color: #323131;
}

.DatePicker {
  border: 1px solid #d0d0d0;
  padding: 0.5rem 1rem;
}

#detailContent img {
  width: 100%;
}